import React from 'react';
import { Box } from '@radix-ui/themes';
import './index.css';

function Logo() {
  return (
    <Box
      asChild
      height='18px'
      className='ono-Logo'
    >
      <img src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
    </Box>
  );
}

export default Logo;
