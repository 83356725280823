import styled, { css } from 'styled-components';

export const Card = styled.div`
  background-color: ${props => props.theme.lightest};
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  ${props =>
    props.dark &&
    css`
      background-color: ${props => props.theme.darker};

      p,
      h2,
      h3 {
        color: ${props => props.theme.lightest};
      }
    `}

  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight}px;
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

export const CardHeader = styled.div`
  padding: 10px 20px;
  font-size: 1.2rem;

  & h3 {
    margin: 0;
  }

  ${props =>
    props.hasControls &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

  ${props =>
    props.dark &&
    css`
      border-bottom: 1px solid ${props => props.theme.copperLightest};

      p,
      h2,
      h3 {
        color: ${props => props.theme.copperLightest};
      }
    `}
`;

export const CardContent = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardRow = styled.div`
  font-size: 1rem;
`;
export const CardSubHeader = styled.div`
  font-size: 0.8rem;
  opacity: 50%;
`;

function getWidth(props) {
  return props.width ? props.width : Math.random() > 0.5 ? '60%' : '80%';
}

export const CardRowLoading = styled.div`
  height: 1.2rem;
  width: ${getWidth};
  margin-bottom: 10px;
  background-color: ${props => props.theme.bgColor.muted};
`;

export const CardContentBreaker = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.bgColor.muted};
`;

export const CardTabs = styled.div`
  ${Card} {
    display: none;

    &:active {
      display: default;
    }
  }
`;
