import { useState } from 'react';
import useTagsSearch from '~/hooks/use-tags-search';
import useTiering from './use-tiering';

function useSearchSimsFilters(organizationId, initialFilter = null) {
  const tagsSearch = useTagsSearch('tags', organizationId, true);
  const connectorsSearch = useTagsSearch('connectors', organizationId, true);
  const networkListsSearch = useTagsSearch('network-lists', organizationId, true);
  const tiering = useTiering();
  const [activeFilters, setActiveFilters] = useState(initialFilter ?? []);
  function clear() {
    if (activeFilters.length <= 0) return;
    setActiveFilters([]);
  }

  function removeValue(key, value) {
    setActiveFilters(activeFilters.filter(filter => filter.key !== key || filter.value !== value));
  }

  function setValue(key, value) {
    const newFilter = { key, value };

    function checkIfKeyIsPresent(filter) {
      // The status keys are mutually exclusive, i.e. there can only be one at a time, which is why we need this extra check
      const statusRelatedKeys = ['activated', 'online'];
      const isStatusRelatedKey = statusRelatedKeys.includes(key);
      return !isStatusRelatedKey ? filter.key === key : statusRelatedKeys.includes(filter.key);
    }

    const uniqueFilters = [...activeFilters.filter(currentFilter => !checkIfKeyIsPresent(currentFilter)), newFilter];
    setActiveFilters(uniqueFilters);
  }

  function getValue(key, items) {
    if (!items.length) return '';
    const currentFilter = activeFilters.find(filter => filter.key === key);
    if (!currentFilter) return '';
    const filterItem = items.find(item => item.label === currentFilter.value);
    if (!filterItem) return '';
    return filterItem.value;
  }

  function getSimType() {
    const currentFilter = activeFilters.find(filter => filter.key === 'isSoftSim');
    if (!currentFilter) return '';
    return 'isSoftSim:' + currentFilter.value;
  }

  function getStatus() {
    const currentStatusActivated = activeFilters.find(filter => filter.key === 'activated');
    const currentStatusOnline = activeFilters.find(filter => filter.key === 'online');
    if (!currentStatusActivated && !currentStatusOnline) return '';
    if (currentStatusActivated) return 'activated:' + currentStatusActivated.value;
    if (currentStatusOnline) return 'online:' + currentStatusOnline.value;
  }

  const connectorSelector = {
    placeholder: 'Connector',
    key: 'connector',
    type: 'select',
    value: getValue('connector', connectorsSearch.items),
    onChange: value => {
      const item = connectorsSearch.items.find(item => item.value === value);
      setValue('connector', item.label);
    },
    onDeselect: value => {
      const item = connectorsSearch.items.find(item => item.label === value);
      removeValue('connector', item?.label ?? value);
    },
    width: '180px',
    items: [
      {
        category: { label: 'Connector' },
        items: connectorsSearch.items,
      },
    ],
    loading: connectorsSearch.loading,
    search: connectorsSearch.search,
    setSearch: connectorsSearch.setSearch,
    startPaging: connectorsSearch.startPaging,
    canSearch: connectorsSearch.canSearch,
  };

  const filters = {
    activeFilters,
    clear,
    setValue,
    filters: [
      {
        placeholder: 'Tags',
        key: 'tag',
        type: 'select',
        value: getValue('tag', tagsSearch.items),
        onChange: value => {
          console.log('value', value);
          const item = tagsSearch.items.find(item => item.value === value);
          setValue('tag', item.label);
        },
        onDeselect: value => {
          const item = tagsSearch.items.find(item => item.label === value);
          removeValue('tag', item?.label ?? value);
        },
        width: '180px',
        items: [
          {
            category: { label: 'Tags' },
            items: tagsSearch.items,
          },
        ],
        loading: tagsSearch.loading,
        search: tagsSearch.search,
        setSearch: tagsSearch.setSearch,
        startPaging: tagsSearch.startPaging,
        canSearch: tagsSearch.canSearch,
      },
      {
        placeholder: 'Network List',
        key: 'networkWhitelist',
        type: 'select',
        value: getValue('networkWhitelist', networkListsSearch.items),
        onChange: value => {
          const item = networkListsSearch.items.find(item => item.value === value);
          setValue('networkWhitelist', item.label);
        },
        onDeselect: value => {
          const item = networkListsSearch.items.find(item => item.label === value);
          removeValue('networkWhitelist', item?.label ?? value);
        },
        width: '180px',
        items: [
          {
            category: { label: 'Network List' },
            items: networkListsSearch.items,
          },
        ],
        loading: networkListsSearch.loading,
        search: networkListsSearch.search,
        setSearch: networkListsSearch.setSearch,
        startPaging: networkListsSearch.startPaging,
        canSearch: networkListsSearch.canSearch,
      },
      {
        placeholder: 'Status',
        key: 'status',
        type: 'select',
        value: getStatus(),
        onChange: value => {
          const [itemKey, itemValue] = value.split(':');
          setValue(itemKey, itemValue);
        },
        onDeselect: value => {
          if (value === 'Online') removeValue('online', 'true');
          if (value === 'Offline') removeValue('online', 'false');
          if (value === 'Active') removeValue('activated', 'true');
          if (value === 'Deactivated') removeValue('activated', 'false');
        },
        width: '180px',
        items: [
          {
            category: { label: 'Status' },
            items: [
              { label: 'Online', value: 'online:true' },
              { label: 'Offline', value: 'online:false' },
              { label: 'Active', value: 'activated:true' },
              { label: 'Deactivated', value: 'activated:false' },
            ],
          },
        ],
      },
      {
        placeholder: 'Type',
        key: 'isSoftSim',
        type: 'select',
        value: getSimType(),
        onChange: value => {
          const [itemKey, itemValue] = value.split(':');
          setValue(itemKey, itemValue);
        },
        onDeselect: value => {
          if (value === 'SoftSim') removeValue('isSoftSim', 'true');
          if (value === 'Physical') removeValue('isSoftSim', 'false');
        },
        width: '180px',
        items: [
          {
            category: { label: 'Type' },
            items: [
              { label: 'SoftSim', value: 'isSoftSim:true' },
              { label: 'Physical', value: 'isSoftSim:false' },
            ],
          },
        ],
      },
    ],
  };

  if (tiering.hasFeature('connectors')) filters.filters.splice(2, 0, connectorSelector);

  return filters;
}

export default useSearchSimsFilters;
