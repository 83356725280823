import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { HeartPulse } from '@styled-icons/remix-fill/HeartPulse';
import useOrganizations from '~/hooks/use-organizations';
import useAuthentication from '~/hooks/use-authentication';
import useClipboard from '~/hooks/use-clipboard';
import StyledNavLink from '~/components/NavLink';
import Button from '~/components/Button';
import { Card } from '~/components/Card';
import HealthStatus from '~/components/HealthStatus';
import useStatuspage from '~/hooks/use-statuspage';
import Input from '~/components/Input';
import { transparentize } from 'polished';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';
import InternalLink from '~/components/InternalLink';
import { useAuth } from '@clerk/clerk-react';
import { Typography } from '~/components/Text';
import { TabNav, Flex, Grid, Text } from '@radix-ui/themes';

const AdminNavLink = styled(StyledNavLink)`
  color: white !important;
  border-bottom: none !important;
`;

const NavLogo = styled.img`
  height: 22px;
  margin: 0 17px 6px 0;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
`;

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.font.primary};

  &:visited {
    color: ${props => props.theme.font.primary};
  }
`;

const StyledSelect = styled(Input)`
  font-size: 1rem;
  border-bottom: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300;
`;

const StyledActivityIcon = styled(HeartPulse)`
  color: white;
`;

const DropdownContainer = styled.div`
  z-index: ${props => props.theme.zIndex.navbar + 1};
  position: relative;
  display: inline-block;
`;
const DropdownTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.font.main};
  cursor: pointer;
  align-items: center;
  height: 30px;
`;
const DropdownWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;

  ${DropdownContainer}:hover & {
    display: block;
  }
`;
const DropdownContent = styled(Card)`
  padding: 10px 0;
  color: ${props => props.theme.font.main};
  border: 1px solid rgba(1, 1, 46, 0.13);
  min-width: 160px;
`;
const DropdownItem = styled.div`
  padding: 8px 18px;
  &:hover {
    background: ${props => transparentize(0.4, props.theme.bgColor.muted)};
  }
`;
const DropdownBreaker = styled.div`
  height: 1px;
  background-color: ${props => props.theme.bgColor.muted};
  width: 100%;
  margin: 8px 0 8px 0;
`;
const DropdownOrgNameWrapper = styled.div`
  padding: 0 20px;
`;

function Navigation() {
  const clipboard = useClipboard();
  const location = useLocation();
  const {
    email,
    isOrgMember,
    isOwner,
    isDemiGod,
    isGod,
    assumeOrganizationId,
    assumedOrganizationId,
    isViewingAsAssumedOrganization,
    toggleIsViewingAsAssumedOrganization,
    usersOrganizations,
    usersCurrentOrganizationId,
    changeOrganization,
  } = useAuthentication();
  const { getToken: clerkGetToken } = useAuth();
  const { overallStatus, networkStatus, webhooksStatus, apiStatus, appStatus } = useStatuspage();
  const organizations = useOrganizations();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const assumedOrganizationActivityUrl =
    assumedOrganizationId &&
    !organizations.loading &&
    organizations.data.find(({ id }) => id === assumedOrganizationId)?.activityUrl;
  const organizationsItems = organizations.loading
    ? [{ label: 'Loading', value: '' }]
    : organizations.data
    ? [
        {
          label: assumedOrganizationId !== null ? 'Cancel' : 'Choose organization',
          value: '',
        },
        ...organizations.data.map(a => ({
          label: `${a.id} - ${a.entity}`,
          value: a.id,
        })),
      ]
    : [];

  async function copyClerkToken() {
    const clerkToken = await clerkGetToken({ template: process.env.CLERK_LONGER_LASTING_TOKEN_TEMPLATE });
    clipboard.write(clerkToken);
  }

  return (
    <Flex
      direction='column'
      pt='2'
      gap='4'
    >
      <Grid
        columns={{ initial: '1', md: '2' }}
        width='auto'
        px='8'
      >
        <Flex
          width='100%'
          justify='start'
          align='center'
          gap='4'
        >
          <InternalLink url='/'>
            <NavLogo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
          </InternalLink>
          <HealthStatus
            overall={overallStatus}
            network={networkStatus}
            webhooks={webhooksStatus}
            api={apiStatus}
            app={appStatus}
          />
        </Flex>
        <Flex
          width='100%'
          justify='end'
        >
          {(isDemiGod || isGod) && (
            <Button
              variant='danger'
              onClick={toggleIsViewingAsAssumedOrganization}
            >
              {!isViewingAsAssumedOrganization
                ? 'View as assumed organization'
                : 'Disable "View as assumed organization"'}
            </Button>
          )}
          {(isDevelopment || isGod) && clipboard.canWrite && (
            <Button
              variant='danger'
              onClick={() => copyClerkToken()}
            >
              Copy token
            </Button>
          )}
          {assumedOrganizationActivityUrl && (isDemiGod || isGod) && (
            <a
              href={assumedOrganizationActivityUrl}
              target='_blank'
              rel='noreferrer'
            >
              <StyledActivityIcon size={20} />
            </a>
          )}
          {(isDemiGod || isGod) && (
            <>
              <StyledSelect
                type='select'
                noMargin
                canSearch
                width='300px'
                items={organizationsItems}
                value={assumedOrganizationId || ''}
                onChange={assumeOrganizationId}
                placeholder='Choose organization'
                optionsWrapperProps={{
                  maxHeight: '300px',
                }}
              />
              <div>
                <AdminNavLink to='/admin'>Admin</AdminNavLink>
              </div>
            </>
          )}
          <DropdownContainer>
            <DropdownTrigger>
              <Typography weight='medium'>{email}</Typography>
              <Icon
                name='chevron-down'
                width={16}
                height={16}
                color={theme.darkest}
              />
            </DropdownTrigger>

            <DropdownWrapper>
              <DropdownContent>
                <DropdownOrgNameWrapper>
                  {usersOrganizations.length > 1 ? (
                    <StyledSelect
                      type='select'
                      noMargin
                      items={usersOrganizations.map(o => ({
                        label: o?.name,
                        value: o?.id,
                      }))}
                      value={usersCurrentOrganizationId}
                      onChange={changeOrganization}
                    />
                  ) : (
                    <strong>{usersOrganizations?.[0].name}</strong>
                  )}
                </DropdownOrgNameWrapper>
                <DropdownBreaker />
                <StyledLink to='/settings'>
                  <DropdownItem>Settings</DropdownItem>
                </StyledLink>
                {(isOwner || isDemiGod || isGod) && (
                  <StyledLink to='/invoices'>
                    <DropdownItem>Invoice Overview</DropdownItem>
                  </StyledLink>
                )}
                <a
                  href='https://help.onomondo.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <DropdownItem>Knowledge Base</DropdownItem>
                </a>
                <a
                  href='https://docs.onomondo.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <DropdownItem>API Docs</DropdownItem>
                </a>
                <DropdownBreaker />
                <StyledLink to='/logout'>
                  <DropdownItem>Log Out</DropdownItem>
                </StyledLink>
              </DropdownContent>
            </DropdownWrapper>
          </DropdownContainer>
        </Flex>
      </Grid>

      <TabNav.Root
        color='gray'
        justify='start'
      >
        <Flex pl='7'>
          {[
            { label: 'Home', to: '/dashboard', iconName: 'home' },
            { label: 'SIMs', to: '/sims', iconName: 'sim-card-solid' },
            { label: 'Usage', to: '/usage', iconName: 'bar-chart-10' },
            { label: 'Networks', to: '/network-lists', iconName: 'globe' },
            { label: 'Users', to: '/users', iconName: 'user', isOrgMemberRestricted: true },
            { label: 'Tags', to: '/tags', iconName: 'tag', isOrgMemberRestricted: true },
            { label: 'Error Log', to: '/error-log', iconName: 'brackets-x', isOrgMemberRestricted: true },
            {
              label: 'Webhooks',
              to: '/webhooks',
              iconName: 'webhooks',
              isOrgMemberRestricted: true,
              featureName: 'management.webhooks',
            },
            {
              label: 'API Keys',
              to: '/api-keys',
              iconName: 'key-01',
              featureName: 'management.apiKeys',
            },
            {
              label: 'Connectors',
              to: '/connectors',
              iconName: 'link-02',
              featureName: 'connectors',
            },
            {
              label: 'Audit Log',
              to: '/audit-logs',
              iconName: 'fingerprint-04',
              featureName: 'management.auditLogs',
              isForOwnerAndOrGodOnly: true,
            },
          ].map(item => {
            if (item.isOrgMemberRestricted && isOrgMember) return null;
            if (item.isForOwnerAndOrGodOnly && !(isOwner || isGod)) return null;
            return (
              <TabNav.Link
                asChild
                key={item.label}
                active={location.pathname.includes(item.to)}
              >
                <NavLink to={item.to}>
                  <Flex gap='2'>
                    <Icon
                      name={item.iconName}
                      width={20}
                      height={20}
                      color='currentColor'
                    />
                    <Text
                      size='2'
                      weight='medium'
                    >
                      {item.label}
                    </Text>
                  </Flex>
                </NavLink>
              </TabNav.Link>
            );
          })}
        </Flex>
      </TabNav.Root>
    </Flex>
  );
}

export default Navigation;
