import React from 'react';
import { useLocation } from 'react-router-dom';
import useOrganizations from '~/hooks/use-organizations';
import useAuthentication from '~/hooks/use-authentication';
import useClipboard from '~/hooks/use-clipboard';
import useStatuspage from '~/hooks/use-statuspage';
import useThemePicker from '~/hooks/use-theme-picker';
import { useAuth, useClerk } from '@clerk/clerk-react';
import { Top, Menu } from '~/components/Navigation';

function Navigation() {
  const clipboard = useClipboard();
  const { getToken } = useAuth();
  const { openUserProfile } = useClerk();
  const { theme: currentTheme, switchTheme } = useThemePicker();
  const location = useLocation();
  const {
    email,
    isOrgMember,
    isOwner,
    isDemiGod,
    isGod,
    assumeOrganizationId,
    assumedOrganizationId,
    toggleIsViewingAsAssumedOrganization,
    usersOrganizations,
    usersCurrentOrganizationId,
    changeOrganization,
  } = useAuthentication();
  const status = useStatuspage();
  const organizations = useOrganizations();

  return (
    <>
      <Top
        clipboard={clipboard}
        theme={{ currentTheme, switchTheme }}
        clerk={{ getToken, openUserProfile }}
        userInfo={{
          email,
          isOrgMember,
          isOwner,
          isDemiGod,
          isGod,
          assumeOrganizationId,
          assumedOrganizationId,
          toggleIsViewingAsAssumedOrganization,
          usersOrganizations,
          usersCurrentOrganizationId,
          changeOrganization,
        }}
        status={status}
        organizations={organizations}
      />
      <Menu
        location={location}
        userInfo={{ isOrgMember, isOwner, isGod }}
      />
    </>
  );
}

export default Navigation;
