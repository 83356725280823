import { createGlobalStyle, css } from 'styled-components';
import 'modali/dist/modali.css';

const query = new URLSearchParams(location.search);
const isDarkMode = query.get('theme') === 'dark';

// clerk overrides general css styles
const clerkFontFamilyCss = css`
  font-family: 'Neue Montreal';
  font-style: normal;
`;

const clerkBaseFontCss = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
`;

const clerkThinFontCss = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

const clerkSmallFontCss = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const clerkSmallThickFontCss = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const clerkBigFontCss = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
`;

const clerkErrorCss = css`
  text-align: left;
  color: rgba(205, 0, 8, 0.76);
  ${clerkSmallFontCss}
`;

const clerkBlackButtonBaseCss = css`
  border-radius: 9999px;
  background: #1c2024;
  display: flex;
  height: 40px;
  padding: 0px ${props => props.theme.spacing[4]};
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing[3]};
  align-self: stretch;
`;

const clerkBlackButtonSmallCss = css`
  border-radius: 9999px;
  background: #1c2024;
  display: flex;
  height: 32px;
  padding: 0px ${props => props.theme.spacing[3]};
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing[2]};
  align-self: stretch;
  text-transform: none;
  color: #fcfcfd;
`;

const clerkLightButtonSmallCss = css`
  border-radius: 9999px;
  background: rgba(0, 0, 59, 0.05);
  display: flex;
  height: 32px;
  padding: 0px ${props => props.theme.spacing[3]};
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spacing[2]};
  align-self: stretch;
  text-transform: none;
  color: rgba(0, 7, 19, 0.62);
`;

const clerkFactorStepsBaseCss = css`
  gap: ${props => props.theme.spacing[7]};
  .cl-identityPreview {
    justify-content: unset;
    p {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
  }
  .cl-form {
    gap: 0px;
  }
  .cl-formHeaderTitle {
    text-align: left;
    color: #1c2024;
    ${clerkBaseFontCss}
    margin-top: 9px;
  }
  .cl-formHeaderSubtitle {
    text-align: left;
    color: #60646c;
    ${clerkThinFontCss}
  }
  .cl-otpCodeFieldInputs {
    width: 100%;
    justify-content: space-between;
  }
  .cl-otpCodeFieldInput {
    width: 48px;
    max-width: unset;
    color: #1c2024;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  .cl-otpCodeFieldErrorText {
    color: rgba(205, 0, 8, 0.76);
    ${clerkSmallFontCss}
    svg {
      display: none;
    }
  }
  .cl-internal-1s2rqwh {
    width: 100%;
  }
  .cl-formResendCodeLink {
    width: 100%;
    text-align: center;
    color: #60646c;
    ${clerkBaseFontCss}
    margin: 0px;
  }
`;

const clerkBackgroundImageCss = css`
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
`;

// https://gist.github.com/thisisadityarao/cc86efbdd916c6031f8ca3601c107d71
const GlobalStyles = createGlobalStyle`
  /****** IMPORTED FONTS ******/
  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Light.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Light.woff')}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Italic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Italic.woff')}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Medium.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Medium.woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Regular.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Regular.woff')}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff')}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff')}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Bold.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Bold.woff')}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff')}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${require('~/assets/fonts/Roboto-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }

  /* ==========================================================================
    GLOBAL STYLES
    ========================================================================== */

  /* Box Sizing
    ========================================================================== */
  html {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: 'NeueMontreal', sans-serif;
    font-weight: 300;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    /* background-color: ${props => props.theme.bgColor.muted}; */
    background-color: ${props => props.theme.gray};
  }

  #root {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* PARAGRAPHS
    ========================================================================== */

  p {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    hyphens: auto;
  }

  /* HEADINGS
    ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Neue Montreal';
    color: ${props => props.theme.darker};
    margin: 0;
  }

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    color: ${props => props.theme.dark};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  h3 {
    color: ${props => props.theme.copperDarkest};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.theme.darker};
  }

  /* TABLES
    ========================================================================== */

  tbody {
    tr:last-child {
      td, th {
        box-shadow: none;
      }
    }
  }

  th {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    vertical-align: bottom;
  }

  td {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
    hyphens: auto;
  }

  /* LINKS
    ========================================================================== */

  a {
    text-decoration: none;
    color: ${props => props.theme.link.color};
  }
  a[data-underline] {
    text-decoration: underline;
  }

  /* A better looking default horizontal rule
    ========================================================================== */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  ========================================================================== */

  img,
  svg {
    vertical-align: middle;
  }

  /* IMAGES
    ========================================================================== */

  img {
    height: auto;
    width: auto;
    /* max-width allows image to be responsive. */
    max-width: 100%;
  }

  /* LISTS
    ========================================================================== */
  ul {
    padding-left: ${props => props.theme.spacing[5]};
  }

  /* dont add padding from modali when it's open */
  @media (min-width: 1025px) {
    .modali-open {
      padding-right: 0 !important;
      overflow: unset !important;
    }
  }

  /* make modal body from modali scrollable */
  .modali-body-style {
    max-height: 70vh;
    overflow-y: auto;
  }

  .modali-overlay {
    z-index: ${props => props.theme.zIndex.modal};
  }
  .modali-wrapper {
    z-index: ${props => props.theme.zIndex.modal + 1};
  }

  /* stop modali from using both in animation-fill-mode */
  .modali-animated {
    animation-fill-mode: none !important;
  }

  /* clerk overrides */
  .cl-rootBox {
    ${clerkFontFamilyCss}
  }
  .cl-card {
    min-width: 344px;
    box-shadow: none;
    margin: 0px 17px;
    padding: 28.5px 2px 36px;
    @media (max-width: 1025px) {
      min-width: 0px;
    }
  }

  .cl-header {
    margin: ${props => props.theme.spacing[5]} 0;
    gap: ${props => props.theme.spacing[2]};
  }

  .cl-headerTitle {
    color: #1C2024;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }

  .cl-headerSubtitle {
    text-align: center;
    color: #60646C;
    ${clerkThinFontCss}
  }

  .cl-headerBackRow {
    justify-content: center;
    a {
      gap: ${props => props.theme.spacing[2]};
      svg {
        height: 16px;
        width: 16px;
        color: #60646C;
      }
    }
  }

  .cl-headerBackLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-alternativeMethods {
    gap: ${props => props.theme.spacing[3]};
  }

  .cl-socialButtons {
    gap: ${props => props.theme.spacing[3]};
  }

  .cl-alternativeMethodsBlockButton {
    ${clerkBlackButtonBaseCss}
    :hover {
      border-radius: 9999px;
      background: #1C2024;
      svg {
        width: 0px;
        height: 0px;
        stroke: #1C2024;
      }
    }
    svg {
      width: 0px;
      height: 0px;
    }
  }

  .cl-alternativeMethodsBlockButtonText {
    color: #FCFCFD;
    ${clerkBigFontCss}
  }

  .cl-internal-egu47 {
    justify-content: center;
  }

  .cl-identityPreview {
    height: 32px;
    max-width: unset;
    justify-content: space-between;
    gap: ${props => props.theme.spacing[2]};
    .cl-identityPreviewText {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
    div {
      margin-right: 0px;
    }
    p {
      margin-right: 0px;
    }
    button {
      margin-left: auto;
      padding: 0px;
      min-height: unset;
      svg {
        display: none;
      }
      background-image: url(${require('~/assets/graphics/edit-05.svg')});
      ${clerkBackgroundImageCss}
    }
  }

  .cl-signIn-forgotPasswordMethods {
    .cl-identityPreview {
        justify-content: unset;
    }
    .cl-internal-uqm3h2 {
      .cl-alternativeMethodsBlockButton {
        border-radius: 9999px;
        border: 1px solid rgba(1, 6, 47, 0.17);
        justify-content: center;
        gap: ${props => props.theme.spacing[2]};
        background: #ffffff;
        div {
          width: unset;
        }
        :hover {
          background: rgba(0, 0, 0, 0.04);
          svg {
            width: 0px;
            height: 0px;
            stroke: #1C2024;
          }
        }
      }
      .cl-alternativeMethodsBlockButtonText {
        color: #60646C;
        ${clerkBaseFontCss}
      }
    }
  }

  .cl-signIn-totp2Fa {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: ${props => props.theme.spacing[4]};
      margin-bottom: 0px;
    }
    svg {
      background-image: url(${require('~/assets/graphics/shield-tick.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-signIn-emailLinkVerify {
    ${clerkFactorStepsBaseCss}
    .cl-formHeaderTitle {
      text-align: center;
    }
    .cl-formHeaderSubtitle {
      text-align: center;
    }
    .cl-formResendCodeLink {
      margin-top: ${props => props.theme.spacing[4]};
    }
  }

  .cl-signIn-resetPassword {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: ${props => props.theme.spacing[4]};
      margin-bottom: ${props => props.theme.spacing[7]};
    }
    .cl-formResendCodeLink {
      width: 100%;
      text-align: center;
      color: #60646C;
      ${clerkBaseFontCss}
      margin: 0px;
    }
  }

  .cl-signIn-havingTrouble {
    .cl-main {
      button {
        ${clerkBlackButtonBaseCss}
        div {
          span {
            color: #FCFCFD;
            ${clerkBigFontCss}
          }
        }
        svg {
          width: 0px;
          heigth: 0px;
          stroke: #1C2024;
        }
      }
    }
  }

  .cl-avatarBox {
    background-image: url(${require('~/assets/graphics/user-03.svg')});
    ${clerkBackgroundImageCss}
    background-color: unset;
    img {
      display:none;
    }
  }

  .cl-socialButtonsBlockButtonText {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-socialButtonsBlockButton {
    border-radius: 9999px;
    border: 1px solid rgba(1, 6, 47, 0.17);
    justify-content: center;
    gap: ${props => props.theme.spacing[2]};
    div {
      width: unset;
    }
  }

  .cl-main {
    gap: ${props => props.theme.spacing[7]};
    p {
      color: #60646C;
      text-align: center;
      ${clerkSmallFontCss}
    }
  }

  .cl-formButtonPrimary {
    ${clerkBlackButtonBaseCss}
    margin-top: 24px;
    color: #FCFCFD;
    ${clerkBigFontCss}
    text-transform: none;
  }

  .cl-form {
    gap: ${props => props.theme.spacing[4]};
  }

  .cl-formField {
    gap: ${props => props.theme.spacing[1]};
    display: flex;
  }

  .cl-formFieldLabel {
    color: ${props => props.theme.color.neutral[12]};
    ${clerkBaseFontCss}
  }

  .cl-formFieldInput {
    color: ${props => props.theme.color.neutral[12]};
    ${clerkThinFontCss}
    :focus {
      box-shadow: none;
    }
  }

  .cl-formFieldInputGroup {
    .cl-formFieldInputShowPasswordButton {
      box-shadow: none;
    }
  }

  .cl-formFieldAction {
    color: ${props => props.theme.copper};
    ${clerkSmallThickFontCss}
  }

  .cl-formFieldErrorText {
    svg {
      display:none;
    }
  }

  #error-password {
    ${clerkErrorCss}
  }

  #error-confirmPassword {
    ${clerkErrorCss}
  }

  #error-identifier {
    ${clerkErrorCss}
  }

  .cl-alert {
    display: flex;
    width: 100%;
    padding: ${props => props.theme.spacing[3]};
    align-items: flex-start;
    gap: ${props => props.theme.spacing[2]};
    border-radius: 8px;
    border: 1px solid #F9C6C6;
    background: #FFF7F7;
    align-items: center;

    .cl-alertText {
      color: rgba(187, 0, 7, 0.84);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    svg {
      margin-right: 0px;
      background-image: url(${require('~/assets/graphics/info-circle-red.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-footer {
    justify-content: center;
  }

  .cl-footerAction__signIn {
    margin-top: ${props => props.theme.spacing[5]};
    .cl-footerActionLink {
      color: ${props => props.theme.copper};
      ${clerkBaseFontCss}
    }
  }

  .cl-footerActionText {
    color: #1C2024;
    ${clerkThinFontCss}
  }

  .cl-footerActionLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-main ~ div[class^='cl-internal'] {
    display: none;
  }

  /* here is styling for the profile settings modal */
  .cl-userProfile-root {
    .cl-card {
      padding-top: 68px;
      padding-bottom: ${props => props.theme.spacing[5]};
      div:has(> .cl-formButtonPrimary), div:has(> .cl-formButtonReset) {
        background-color: ${props => props.theme.lightest};
        position: absolute;
        right: 24px;
        bottom: 0px;
        width: 100%;
        margin-top: 0px;
      }
    }

    .cl-modalCloseButton {
      margin: ${props => props.theme.spacing[5]} ${props => props.theme.spacing[5]} 0px 0px;
      padding: 3px;
      min-height: ${props => props.theme.spacing[4]};
      max-height: ${props => props.theme.spacing[4]};
      svg {
        height: 10px;
        width: 10px;
      }
    }

    .cl-navbar, .cl-pageScrollBox {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: ${props => props.theme.spacing[5]};
      padding-right: ${props => props.theme.spacing[5]};
    }

    .cl-navbar {
      max-width: 229px;
      .cl-navbarButtons {
        gap: ${props => props.theme.spacing[2]};
        width: 180px;
        .cl-navbarButton {
          gap: ${props => props.theme.spacing[2]};
          padding: 0px ${props => props.theme.spacing[3]};
          height: ${props => props.theme.spacing[6]};
          ${clerkFontFamilyCss}
          color: ${props => props.theme.color.neutral[11]};
          ${clerkBaseFontCss}
          opacity: 1;
          :focus {
            box-shadow: none;
          }
        }
      }
    }

    .cl-navbarButton__account, .cl-navbarButton__security, .cl-formFieldInputCopyToClipboardButton {
      svg {
        height: ${props => props.theme.spacing[4]};
        width: ${props => props.theme.spacing[4]};
        path {
          display: none;
        }
      }
    }

    .cl-navbarButton__account {
      svg {
        background-image: url(${require('~/assets/graphics/user-03.svg')});
      }
    }

    .cl-navbarButton__security {
      svg {
        background-image: url(${require('~/assets/graphics/shield-tick.svg')});
      }
    }

    .cl-profileSectionPrimaryButton__password {
      span {
        svg {
          background-image: url(${require('~/assets/graphics/edit-02.svg')});
        }
      }
    }

    .cl-profileSectionPrimaryButton__mfa {
      span {
        svg {
          background-image: url(${require('~/assets/graphics/plus.svg')});
        }
      }
    }

    .cl-scrollBox {
      .cl-pageScrollBox {
        margin-bottom: ${props => props.theme.spacing[7]};
        /* the following is selecting the right side of the profile settings modal main page */
        .cl-page {
          gap: ${props => props.theme.spacing[7]};
          .cl-profilePage__account {
            margin-bottom: ${props => props.theme.spacing[4]};
            .cl-userPreview {
              gap: ${props => props.theme.spacing[2]};
            }
          }
          .cl-profilePage__security {
            .cl-profileSectionContent__password {
              p {
                padding: ${props => props.theme.spacing[1]} 0px;
              }
            }
            .cl-accordionContent {
              padding: ${props => props.theme.spacing[2]} ${props => props.theme.spacing[3]};
              div {
                gap: ${props => props.theme.spacing[1]};
                div {
                  gap: 0px;
                  button {
                    margin-top: ${props => props.theme.spacing[1]};
                    color: ${props => props.theme.color.neutral[11]};
                    ${clerkSmallThickFontCss}
                    :focus {
                      box-shadow: none;
                    }
                  }
                  div {
                    div {
                      p {
                        color: ${props => props.theme.color.neutral[12]};
                        ${clerkSmallThickFontCss}
                      }
                    }
                    p {
                      color: ${props => props.theme.color.neutral[11]};
                      ${clerkSmallFontCss}
                    }
                  }
                }
              }
            }
            .cl-profileSectionContent__mfa {
              .cl-accordionTriggerButton {
                div {
                  span:nth-child(1) {
                    color: ${props => props.theme.color.neutral[12]};
                    ${clerkBaseFontCss}
                  }
                }
                span {
                  svg {
                    height: 16px;
                    width: 16px;
                    path {
                      display: none;
                    }
                  }
                }
                :focus {
                  box-shadow: none;
                }
              }
              div:nth-child(1) {
                .cl-accordionTriggerButton {
                  span {
                    svg {
                      background-image: url(${require('~/assets/graphics/fingerprint-01.svg')});
                    }
                  }
                }
                button[data-localization-key='userProfile.start.mfaSection.totp.destructiveActionLabel'] {
                  color: ${props => props.theme.color.error[11]};
                  :focus {
                    box-shadow: none;
                  }
                }
              }
              div:nth-child(2) {
                .cl-accordionTriggerButton {
                  span {
                    svg {
                      background-image: url(${require('~/assets/graphics/passcode.svg')});
                    }
                  }
                }
              }
            }
            .cl-profileSectionContent__activeDevices {
              .cl-activeDevice {
                gap: ${props => props.theme.spacing[4]};
                div {
                  gap: 0px;
                  div {
                    p {
                      margin-right: ${props => props.theme.spacing[2]};
                      color: ${props => props.theme.color.neutral[12]};
                      ${clerkBaseFontCss}
                    }
                  }
                  p {
                    color: ${props => props.theme.color.neutral[11]};
                    ${clerkThinFontCss}
                  }
                }
              }
              .cl-accordionTriggerButton {
                :focus {
                  box-shadow: none;
                }
                :hover {
                  border-radius: ${props => props.theme.radius[2]};
                  border: 1px solid ${props => props.theme.color.neutral[6]};
                  background: ${props => props.theme.color.neutral[2]};
                }
              }
              .cl-accordionContent {
                button {
                  color: ${props => props.theme.color.error[11]};
                  :focus {
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
        .cl-header {
          margin: 0px;
          h1 {
            color: ${props => props.theme.color.neutral[12]};
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.1px;
          }
          p {
            ${clerkThinFontCss}
            color: ${props => props.theme.color.neutral[11]};
          }
        }
        .cl-breadcrumbs {
          margin-bottom: ${props => props.theme.spacing[6]};
          .cl-breadcrumbsItems {
            a {
              color: ${props => props.theme.color.neutral[11]};
              ${clerkSmallThickFontCss}
              svg {
                display: none;
              }
            }
            p {
              color: ${props => props.theme.color.neutral[12]};
              ${clerkSmallThickFontCss}
              align-items: center;
              margin-bottom: 0px;
            }
          }
        }
        /* the following is selecting the right side of the profile settings modal,
        but it is not selecting the main page,
        rather the pages you can go into like when you click the 'Change password' button */
        :has(.cl-form),
        :has(p[data-localization-key='userProfile.backupCodePage.successMessage']),
        :has(.cl-qrCodeRow),
        :has(.cl-formFieldInputCopyToClipboardButton) {
          div {
            .cl-form {
              .cl-formFieldLabelRow__lastName, .cl-formField__newPassword, .cl-formField__confirmPassword  {
                margin-top: ${props => props.theme.spacing[5]};
              }
              .cl-avatarBox {
                display: none;
              }
              .cl-avatarBox + div {
                display: none;
              }
              .cl-formField__signOutOfOtherSessions {
                margin-top: ${props => props.theme.spacing[5]};
                input {
                  accent-color: black;
                }
              }
              .cl-formFieldLabelRow__signOutOfOtherSessions {
                margin-left: ${props => props.theme.spacing[2]};
              }
            }
            p {
              color: ${props => props.theme.color.neutral[12]};
              ${clerkThinFontCss}
              margin-bottom: ${props => props.theme.spacing[2]};
            }
          }
        }
        /* the following is selecting a div inside a page with the qr code,
        you can access it by clicking on 'Add two-step verification' button */
        div:has(.cl-qrCodeRow) {
          .cl-qrCodeContainer {
            padding: ${props => props.theme.spacing[2]};
          }
          div {
            div {
              gap: 0px;
              p {
                color: ${props => props.theme.color.neutral[12]};
                ${clerkThinFontCss}
                margin-bottom: 0px;
              }
              button[data-localization-key='userProfile.mfaTOTPPage.authenticatorApp.buttonUnableToScan__nonPrimary'] {
                margin-top: ${props => props.theme.spacing[4]};
                padding-left: ${props => props.theme.spacing[2]};
                color: ${props => props.theme.color.neutral[11]};
                ${clerkSmallThickFontCss}
                :focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
        /* the following selection can be accessed by clicking on 'Add two-step verification' button,
        then clicking the 'Can't scan QR code?' button */
        div:has(.cl-formFieldInputCopyToClipboardButton) {
          div {
            div {
              gap: 0px;
              p[data-localization-key='userProfile.mfaTOTPPage.authenticatorApp.infoText__unableToScan'] {
                color: ${props => props.theme.color.neutral[12]};
                ${clerkThinFontCss}
              }
              p[data-localization-key='userProfile.mfaTOTPPage.authenticatorApp.inputLabel__unableToScan1'],
              p[data-localization-key='userProfile.mfaTOTPPage.authenticatorApp.inputLabel__unableToScan2'] {
                margin: 0px;
                color: ${props => props.theme.color.neutral[11]};
                ${clerkSmallFontCss}
              }
              div {
                margin-bottom: ${props => props.theme.spacing[4]};
                input {
                  color: ${props => props.theme.color.neutral[12]};
                  ${clerkThinFontCss}
                  opacity: 1;
                }
              }
              button[data-localization-key='userProfile.mfaTOTPPage.authenticatorApp.buttonAbleToScan__nonPrimary'] {
                margin-top: ${props => props.theme.spacing[2]};
                padding-left: ${props => props.theme.spacing[2]};
                color: ${props => props.theme.color.neutral[11]};
                ${clerkSmallThickFontCss}
                :focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
        /* the following selection can be accessed by clicking on 'Add two-step verification' button,
        then clicking the 'Continue' button */
        div:has(.cl-otpCodeField) {
          gap: 0px;
          .cl-form {
            margin-top: ${props => props.theme.spacing[5]};
            gap: 0px;
          }
          .cl-formHeaderTitle {
            color: ${props => props.theme.color.neutral[12]};
            margin-bottom: 0px;
            ${clerkBaseFontCss}
          }
          .cl-formHeaderSubtitle {
            color: ${props => props.theme.color.neutral[11]};
            margin-bottom: ${props => props.theme.spacing[4]};
            ${clerkThinFontCss}
          }
          .cl-otpCodeField {
            height: 48px;
            .cl-otpCodeFieldInputs {
              height: 48px;
              width: 328px;
              gap: ${props => props.theme.spacing[2]};
              input {
                height: 48px;
                width: 48px;
                max-width: unset;
              }
              .cl-otpCodeFieldInput {
                :focus {
                  border-color: black;
                }
              }
            }
          }
        }
        /* the following selection can be accessed by a user with 2fa set,
        can be accessed by clicking on 'Regenerate codes' button under 'Backup codes' */
        .cl-page:has(p[data-localization-key='userProfile.backupCodePage.successMessage']) {
          div:has(p[data-localization-key='userProfile.backupCodePage.successMessage']) {
            gap: 0px;
            p[data-localization-key='userProfile.backupCodePage.successMessage'] {
              margin-bottom: ${props => props.theme.spacing[5]};
            }
            p[data-localization-key='userProfile.backupCodePage.title__codelist'] {
              ${clerkBaseFontCss}
              margin-bottom: 0px;
            }
            p[data-localization-key='userProfile.backupCodePage.subtitle__codelist'] {
              color: ${props => props.theme.color.neutral[11]};
              margin-bottom: 0px;
            }
            div:nth-child(3) {
              margin: ${props => props.theme.spacing[4]} 0px;
              gap: ${props => props.theme.spacing[2]};
              div {
                margin: 0px;
                padding: 2px ${props => props.theme.spacing[2]};
                border-radius: ${props => props.theme.radius[1]};
                background: ${props => props.theme.color.neutralAlpha[3]};
                p {
                  margin-bottom: 0px;
                  color: ${props => props.theme.color.neutralAlpha[11]};
                  ${clerkSmallThickFontCss}
                }
              }
            }
            div:has(button[data-localization-key='userProfile.backupCodePage.actionLabel__copy']) {
              gap: ${props => props.theme.spacing[4]};
            }
            button[data-localization-key='userProfile.backupCodePage.actionLabel__copy'],
            button[data-localization-key='userProfile.backupCodePage.actionLabel__copied'],
            button[data-localization-key='userProfile.backupCodePage.actionLabel__download'],
            button[data-localization-key='userProfile.backupCodePage.actionLabel__print'] {
              color: ${props => props.theme.color.neutral[11]};
              ${clerkSmallThickFontCss}
              :focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .cl-badge {
      border-radius: 3px ${props => props.theme.radius[1]};
      background: ${props => props.theme.color.neutralAlpha[3]};
      display: flex;
      padding: 2px ${props => props.theme.spacing[2]};
      justify-content: center;
      align-items: center;
      color: ${props => props.theme.color.neutralAlpha[11]};
      ${clerkSmallThickFontCss}
    }

    .cl-formFieldInputCopyToClipboardButton {
      margin-top: 0px;
      svg {
        background-image: url(${require('~/assets/graphics/clipboard.svg')});
      }
      :focus {
        box-shadow: none;
      }
    }

    .cl-profilePage {
      #cl-section-account, #cl-section-security {
        margin: 0px;
      }
      gap: ${props => props.theme.spacing[5]};
    }

    .cl-profileSectionTitleText {
      margin-bottom: ${props => props.theme.spacing[2]};
      color: ${props => props.theme.color.neutral[12]};
      ${clerkBigFontCss}
    }

    .cl-profileSectionPrimaryButton {
      color: ${props => props.theme.color.neutral[11]};
      ${clerkBaseFontCss}
      border-radius: ${props => props.theme.radius.full};
      border: 1px solid transparent;
      height: 32px;
      padding: 0px ${props => props.theme.spacing[3]};
      gap: ${props => props.theme.spacing[2]};
      span {
        ${clerkBaseFontCss}
      }
      span {
        svg {
          height: 16px;
          width: 16px;
          path {
            display: none;
          }
        }
      }
      :hover, :active, :focus {
        box-shadow: none;
        color: ${props => props.theme.color.neutral[11]};
        border: 1px solid ${props => props.theme.color.neutralAlpha[7]};
        background-color: ${props => props.theme.color.neutralAlpha[2]};
      }
    }

    .cl-headerTitle, .cl-profileSectionContent, .cl-internal-egu47 {
      justify-content: start;
    }

    .cl-headerTitle, .cl-headerSubtitle {
      text-align: start;
    }

    .cl-navbar ~ div[class^='cl-internal'] {
      display: none;
    }

    .cl-formButtonReset, .cl-formButtonPrimary {
      margin-top: ${props => props.theme.spacing[3]};
      ${clerkBaseFontCss}
      :focus {
        box-shadow: none;
      }
    }

    .cl-formButtonPrimary {
      ${clerkBlackButtonSmallCss}
      :disabled {
        color: ${props => props.theme.color.neutralAlpha[8]};
        background: ${props => props.theme.color.neutralAlpha[3]};
      }
    }

    .cl-formButtonReset {
      ${clerkLightButtonSmallCss}
    }
  }

  .cl-profileSection__emailAddresses, .cl-profileSection__connectedAccounts {
    display: none;
  }

  .highcharts-graph {
    stroke: ${props => props.theme.orange};
  }
  .highcharts-background {
    fill: ${props => (isDarkMode ? 'rgb(24, 25, 27)' : props.theme.white)};
  }
  .highcharts-minor-grid-line {
    stroke: ${isDarkMode ? 'rgb(128, 128, 128)' : 'rgba(1, 1, 46, 0.13)'};
  }
  .highcharts-grid-line {
    stroke: ${isDarkMode ? 'rgb(128, 128, 128)' : 'rgba(1, 1, 46, 0.13)'};
  }
  .highcharts-axis-line {
    stroke: ${isDarkMode ? 'rgb(128, 128, 128)' : 'rgba(1, 1, 46, 0.13)'};
  }
  .highcharts-tick {
    stroke: ${isDarkMode ? 'rgb(128, 128, 128)' : 'rgba(1, 1, 46, 0.13)'};
  }
`;

export default GlobalStyles;
