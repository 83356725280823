import { StrictMode } from 'react';
import { ThemePickerProvider } from '~/context/theme-picker';
import Routes from '~/containers/Routes';
import GlobalStyles from '~/components/GlobalStyles';
import '~/assets/stylesheets/global.css';
import './radix-overrides.css';

function RootContainer() {
  return (
    <StrictMode>
      <ThemePickerProvider>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </ThemePickerProvider>
    </StrictMode>
  );
}

export default RootContainer;
