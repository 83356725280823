import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContinueBtn,
  StyledInput,
  StyledForm,
  StyledLabelContainer,
  SubTitleWrapper,
  StyledContinueBtnWrapper,
  InfoMessage,
  ErrorMessage,
  WarningMessage,
  MessageTop,
  MessageButton,
  PromotionMessage,
  ModalParagraph,
  TopOfForm,
  MidOfForm,
  BottomOfForm,
} from './components/Elements';
import { SignUpCardTitle, Typography } from '~/components/Text';
import useLocationAndTechnologies from '~/hooks/use-location-and-technologies';
import Icon from '~/components/Icon';
import Link from '~/components/Link';
import { interleave } from '~/lib/array';
import styled, { useTheme } from 'styled-components';
import mixpanel from 'mixpanel-browser';

const RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS = 'validated';
const RADIO_ACCESS_TECHNOLOGIES_LIVE_STATUS = 'live';

const LOADING_COUNTRIES = [{ label: 'Loading...', value: 'loading' }];
const GET_IN_TOUCH_LINK_PROPS = {
  url: process.env.GET_IN_TOUCH_CTA_URL,
  method: 'external',
};

const TECH_DISPLAY_NAMES = {
  lteM: 'LTE-M',
  nbIoT: 'NB-IoT',
  '4G': 'LTE',
};

const CountryInput = styled(StyledInput)`
  ${props => !props.value && 'margin-bottom: 40px;'}
`;

function getTechnologiesRenderArray(technologies, validatedAsterisk, roamingAsterisk, addRoamingAsterisk, usePOC) {
  const techArray = technologies.map(t => {
    const techDisplayName = TECH_DISPLAY_NAMES[t.tech] || t.tech;
    const asterisks = [];

    const available = usePOC ? t.availableWithPoc : t.availableWithFreeTrial;
    if (available === RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS) asterisks.push(validatedAsterisk);

    if (addRoamingAsterisk) asterisks.push(roamingAsterisk);

    if (!asterisks.length) {
      return techDisplayName;
    }

    const asterisksString = asterisks.join('/');
    return (
      <i key={t.tech}>
        {techDisplayName}
        <sup>{asterisksString}</sup>
      </i>
    );
  });
  return interleave(techArray, ', ');
}

function convertRadioAccessTechnologyStatusToBoolean(status) {
  return status === RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS || status === RADIO_ACCESS_TECHNOLOGIES_LIVE_STATUS;
}

function filterTechnologiesIntoGroups(technologies) {
  const freeTrialTechnologies = [];
  const techExclusivelyInPoc = [];

  for (const technology of technologies) {
    const availableInFreeTrial = convertRadioAccessTechnologyStatusToBoolean(technology.availableWithFreeTrial);
    const availableInPoc = convertRadioAccessTechnologyStatusToBoolean(technology.availableWithPoc);

    if (availableInFreeTrial) {
      freeTrialTechnologies.push(technology);
      continue;
    }

    if (availableInPoc) {
      techExclusivelyInPoc.push(technology);
    }
  }

  return { freeTrialTechnologies, techExclusivelyInPoc };
}

function NoTechnologies({ techExclusivelyInPoc }) {
  const theme = useTheme();

  const text = techExclusivelyInPoc.length
    ? 'No available free trial networks in this country.'
    : 'No available networks in this country.';

  return (
    <ErrorMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='alert-2'
          color={theme.color.errorAlpha[11]}
        />
        <Typography color={theme.color.errorAlpha[11]}>{text}</Typography>
      </MessageTop>
    </ErrorMessage>
  );
}

NoTechnologies.propTypes = {
  techExclusivelyInPoc: PropTypes.arrayOf(PropTypes.shape()),
};

function ShippingNotAvailable({ location }) {
  const theme = useTheme();

  return (
    <ErrorMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='package-x'
          color={theme.color.errorAlpha[11]}
        />
        <Typography color={theme.color.errorAlpha[11]}>Shipping to this country is unavailable.</Typography>
      </MessageTop>
      {!location.shippingData.importPossible && (
        <MessageButton linkProps={GET_IN_TOUCH_LINK_PROPS}>Get in touch</MessageButton>
      )}
    </ErrorMessage>
  );
}

ShippingNotAvailable.propTypes = {
  location: PropTypes.shape(),
};

function PocPromotion({ techExclusivelyInPoc, pocTechRenderArray }) {
  const theme = useTheme();
  const techWord = techExclusivelyInPoc.length > 1 ? 'technologies' : 'technology';

  return (
    <PromotionMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='coins-hand'
          color={theme.color.accentAlpha[11]}
        />
        <div>
          <Typography color={theme.color.accentAlpha[11]}>Available paid trial network {techWord}:</Typography>
          <Typography color={theme.color.accentAlpha[11]}>{pocTechRenderArray}</Typography>
        </div>
      </MessageTop>
      <MessageButton linkProps={GET_IN_TOUCH_LINK_PROPS}>Get in touch</MessageButton>
    </PromotionMessage>
  );
}

PocPromotion.propTypes = {
  techExclusivelyInPoc: PropTypes.arrayOf(PropTypes.shape()),
  pocTechRenderArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

function CustomsFeeRequired() {
  const theme = useTheme();

  return (
    <WarningMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='coins-stacked-02'
          color={theme.color.warning[11]}
        />
        <Typography color={theme.color.warning[11]}>SIMs shipped to this country may incur a customs fee.</Typography>
      </MessageTop>
    </WarningMessage>
  );
}

function HasTechnologies({ freeTrialTechnologies, freeTechRenderArray }) {
  const theme = useTheme();
  const techWord = freeTrialTechnologies.length > 1 ? 'technologies' : 'technology';

  return (
    <InfoMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='globe'
        />
        <div>
          <Typography color={theme.color.neutralAlpha[11]}>Available free trial network {techWord}:</Typography>
          <Typography color={theme.color.neutralAlpha[11]}>{freeTechRenderArray}</Typography>
        </div>
      </MessageTop>
    </InfoMessage>
  );
}

HasTechnologies.propTypes = {
  freeTrialTechnologies: PropTypes.arrayOf(PropTypes.shape()),
  freeTechRenderArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

const MESSAGE_MAP = {
  noTechnologies: {
    Component: NoTechnologies,
    mixpanelValue: 'Self sign up no available technologies',
  },
  pocPromotion: {
    Component: PocPromotion,
    mixpanelValue: 'Self sign up poc promotion',
  },
  shippingNotAvailable: {
    Component: ShippingNotAvailable,
    mixpanelValue: 'Self sign up shipping not available',
  },
  shippingNotAvailableWithGetInTouch: {
    Component: ShippingNotAvailable,
    mixpanelValue: 'Self sign up shipping not available with get in touch',
  },
  hasTechnologies: {
    Component: HasTechnologies,
    mixpanelValue: 'Self sign up has technologies',
  },
  hasTechnologiesWithRoamingRestrictions: {
    Component: HasTechnologies,
    mixpanelValue: 'Self sign up has technologies with roaming restrictions',
  },
  customsFeeRequired: {
    Component: CustomsFeeRequired,
    mixpanelValue: 'Self sign up customs fee required',
  },
};

function Qualification({ nextStep, formData, unsafeMetadata, RenderBackButton }) {
  const locationAndTechnologies = useLocationAndTechnologies();

  const theme = useTheme();
  const [locations, setLocations] = useState(LOADING_COUNTRIES);
  const [canContinue, setCanContinue] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [countryInformation, setCountryInformation] = useState(null);
  const [messagesToDisplay, setMessagesToDisplay] = useState([]);

  useEffect(() => {
    if (countryCode) {
      const location = locationAndTechnologies.data.find(countryData => countryData.countryCode === countryCode);

      const { freeTrialTechnologies, techExclusivelyInPoc } = filterTechnologiesIntoGroups(location.technologies);
      const hasTech = freeTrialTechnologies.length || techExclusivelyInPoc.length;
      const allPresentTechnologies = [...freeTrialTechnologies, ...techExclusivelyInPoc];

      const hasFreeTrialTechnologiesWithValidatedStatus = freeTrialTechnologies.some(
        t => t.availableWithFreeTrial === RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS,
      );
      const hasPocExclusivelyTechWithValidatedStatus = techExclusivelyInPoc.some(
        t => t.availableWithPoc === RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS,
      );
      const hasTechWithValidatedStatus =
        hasFreeTrialTechnologiesWithValidatedStatus || hasPocExclusivelyTechWithValidatedStatus;

      const allTechnologiesHavePermanentRoamingRestriction =
        !!allPresentTechnologies && allPresentTechnologies.every(t => t.permanentRoamingRestriction);

      const validatedAsterisk = hasTechWithValidatedStatus ? '*' : '';
      const roamingAsterisk = allTechnologiesHavePermanentRoamingRestriction
        ? hasTechWithValidatedStatus
          ? '**'
          : '*'
        : '';

      const freeTechRenderArray = getTechnologiesRenderArray(
        freeTrialTechnologies,
        validatedAsterisk,
        roamingAsterisk,
        allTechnologiesHavePermanentRoamingRestriction,
        false,
      );
      const pocTechRenderArray = getTechnologiesRenderArray(
        techExclusivelyInPoc,
        validatedAsterisk,
        roamingAsterisk,
        allTechnologiesHavePermanentRoamingRestriction,
        true,
      );

      const willShowTech = hasTech && location.shippingData.shippingPossible && location.shippingData.importPossible;

      setCountryInformation({
        location,
        willShowTech,
        freeTrialTechnologies,
        techExclusivelyInPoc,
        showPermanentRoamingRestrictionInfo: allTechnologiesHavePermanentRoamingRestriction,
        showValidatedInfo: hasTechWithValidatedStatus,
        roamingAsterisk,
        validatedAsterisk,
        freeTechRenderArray,
        pocTechRenderArray,
      });
    }
  }, [countryCode, locationAndTechnologies.data]);

  const getMixpanelProperties = useCallback(
    messages => {
      if (!countryInformation) return {};

      const freeTrialTechnologies = countryInformation.freeTrialTechnologies.map(t => t.tech);
      const freeTrialTechnologiesWithValidatedStatus = countryInformation.freeTrialTechnologies
        .filter(t => t.availableWithFreeTrial === RADIO_ACCESS_TECHNOLOGIES_VALIDATED_STATUS)
        .map(t => t.tech);
      const freeTrialTechnologiesWithRoamingRestrictions = countryInformation.freeTrialTechnologies
        .filter(t => t.permanentRoamingRestriction)
        .map(t => t.tech);

      const techExclusivelyInPoc = countryInformation.techExclusivelyInPoc.map(t => t.tech);
      const mixpanelMessageValues = messages.map(({ mixpanelValue }) => mixpanelValue);

      return {
        Email: formData.emailAddress,
        Firstname: formData.firstName,
        Lastname: formData.lastName,
        Company: unsafeMetadata.companyName,
        'Self sign up test country name': countryInformation.location.country,
        'Self sign up test free trial technologies available': freeTrialTechnologies,
        'Self sign up test free trial technologies marked as validated': freeTrialTechnologiesWithValidatedStatus,
        'Self sign up test free trial technologies marked as roaming': freeTrialTechnologiesWithRoamingRestrictions,
        'Self sign up test paid poc extended technologies available': techExclusivelyInPoc,
        'Self sign up test information messages': mixpanelMessageValues,
      };
    },
    [countryInformation, formData.emailAddress, formData.firstName, formData.lastName, unsafeMetadata.companyName],
  );

  const sendMixpanelEvent = useCallback(
    ({ event, messages }) => {
      if (!countryInformation) return;

      const mixpanelProperties = getMixpanelProperties(messages);
      mixpanel.track(event, mixpanelProperties);
    },
    [countryInformation, getMixpanelProperties],
  );

  const getMainMessage = useCallback(() => {
    if (!countryInformation.location.shippingData.shippingPossible) {
      setCanContinue(false);
      return MESSAGE_MAP.shippingNotAvailableWithGetInTouch;
    }

    if (!countryInformation.location.shippingData.importPossible) {
      setCanContinue(false);
      return MESSAGE_MAP.shippingNotAvailable;
    }

    if (!countryInformation.freeTrialTechnologies.length) {
      setCanContinue(false);
      return MESSAGE_MAP.noTechnologies;
    }

    setCanContinue(true);

    if (countryInformation.freeTrialTechnologies.some(t => t.permanentRoamingRestriction)) {
      return MESSAGE_MAP.hasTechnologiesWithRoamingRestrictions;
    }

    return MESSAGE_MAP.hasTechnologies;
  }, [countryInformation]);

  const getMessagesToDisplay = useCallback(() => {
    if (!countryInformation) return [];
    const shippingIsAvailable =
      countryInformation.location.shippingData.shippingPossible &&
      countryInformation.location.shippingData.importPossible;

    const messages = [getMainMessage()];

    if (!shippingIsAvailable) return messages;

    if (countryInformation.techExclusivelyInPoc.length) messages.push(MESSAGE_MAP.pocPromotion);
    if (countryInformation.location.shippingData.customsFeeRequired) messages.push(MESSAGE_MAP.customsFeeRequired);

    return messages;
  }, [countryInformation, getMainMessage]);

  useEffect(() => {
    const messages = getMessagesToDisplay();

    setMessagesToDisplay(messages);
    sendMixpanelEvent({ event: 'Self sign up select country', messages });
  }, [countryInformation, getMessagesToDisplay, sendMixpanelEvent]);

  useEffect(() => {
    if (locationAndTechnologies.data) {
      const newLocations = locationAndTechnologies.data
        .map(({ countryCode, country }) => ({
          label: country,
          value: countryCode,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setLocations(newLocations);
    }
  }, [locationAndTechnologies.data]);

  useEffect(() => {
    if (locationAndTechnologies.error) nextStep();
  }, [locationAndTechnologies.error, nextStep]);

  const CountryInformation = useCallback(() => {
    return messagesToDisplay.map(({ Component }, index) => {
      return (
        <Component
          key={index}
          {...countryInformation}
        />
      );
    });
  }, [messagesToDisplay, countryInformation]);

  function FullNetworkCoverageLink() {
    return (
      <StyledLabelContainer>
        For our full network availability, check our{' '}
        <Link
          target='_blank'
          href={process.env.FULL_NETWORK_COVERAGE_LINK}
          onClick={() =>
            sendMixpanelEvent({ event: 'Self sign up click coverage map link', messages: messagesToDisplay })
          }
        >
          Coverage Map
        </Link>
      </StyledLabelContainer>
    );
  }

  function CanContinueButton() {
    return (
      <StyledContinueBtnWrapper>
        <ContinueBtn
          onClick={e => {
            e.preventDefault();
            nextStep({
              mixpanel: {
                event: 'Self sign up requested verification email',
                properties: getMixpanelProperties(messagesToDisplay),
              },
            });
          }}
        >
          Continue
        </ContinueBtn>
      </StyledContinueBtnWrapper>
    );
  }

  return (
    <>
      <SignUpCardTitle>Testing location</SignUpCardTitle>
      <SubTitleWrapper>
        <ModalParagraph>
          Please select your primary testing location to check our free testing availability.
        </ModalParagraph>
      </SubTitleWrapper>
      <StyledForm marginBottom={!canContinue && 24}>
        <TopOfForm>
          <CountryInput
            type='select'
            canSearch
            noMargin
            label='Primary country for testing'
            placeholder='Select...'
            items={locations}
            onChange={value => {
              setCountryCode(value);
            }}
            value={countryCode}
          />
          {countryInformation && <CountryInformation />}
        </TopOfForm>
        <MidOfForm>
          {countryCode && <FullNetworkCoverageLink />}
          {canContinue && <CanContinueButton />}
          <RenderBackButton />
        </MidOfForm>
        <BottomOfForm>
          {countryInformation?.willShowTech && (
            <>
              {countryInformation?.showValidatedInfo && (
                <Typography
                  type='label'
                  color={theme.color.neutralAlpha[10]}
                >
                  <i>
                    {countryInformation.validatedAsterisk}This technology is validated, it indicates that a network has
                    been independently tested and proven to function at the time of the test. These technologies are not
                    covered by SLA.
                  </i>
                </Typography>
              )}
              {countryInformation?.showPermanentRoamingRestrictionInfo && (
                <Typography
                  type='label'
                  color={theme.color.neutralAlpha[10]}
                >
                  <i>{countryInformation.roamingAsterisk}No permanent roaming available</i>
                </Typography>
              )}
            </>
          )}
        </BottomOfForm>
      </StyledForm>
    </>
  );
}

Qualification.propTypes = {
  nextStep: PropTypes.func,
  formData: PropTypes.object,
  unsafeMetadata: PropTypes.object,
  RenderBackButton: PropTypes.func,
};

export default Qualification;
