import { ThemeProvider } from 'styled-components';
import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import theme from '~/lib/theme/themes';
import defaultTheme from '~/lib/theme/index';
import { Theme as RadixThemesTheme } from '@radix-ui/themes';

const ThemePickerContext = createContext();

function ThemePickerProvider(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const themeParam = urlParams.get('theme');

  const [themeMode, setThemeMode] = useState(themeParam === 'dark' ? 'dark' : 'light');
  const localTheme = themeMode === 'light' ? theme.light : theme.dark;

  return (
    <ThemePickerContext.Provider
      value={{
        theme: themeMode,
        switchTheme: () => {
          setThemeMode(themeMode === 'light' ? 'dark' : 'light');
        },
      }}
    >
      <RadixThemesTheme
        accentColor='orange'
        grayColor='slate'
        panelBackground='solid'
        appearance={themeMode}
      >
        <ThemeProvider
          theme={{ ...defaultTheme, ...localTheme }}
          {...props}
        />
      </RadixThemesTheme>
    </ThemePickerContext.Provider>
  );
}

ThemePickerProvider.propTypes = {
  children: PropTypes.node,
};

export { ThemePickerProvider, ThemePickerContext };
