import colors from './colors';

const light = {
  color: {
    // Accent color. AKA Onomondo Copper
    accent: colors.version3.accent.main,
    accentAlpha: colors.version3.accent.mainAlpha,
    // Neutral color. AKA grayscale
    neutral: colors.version3.neutral.main,
    neutralAlpha: colors.version3.neutral.mainAlpha,
    // Semantic colors. AKA error, success, warning, info
    error: colors.version3.error.main,
    errorAlpha: colors.version3.error.mainAlpha,
    info: colors.version3.info.main,
    infoAlpha: colors.version3.info.mainAlpha,
    success: colors.version3.success.main,
    successAlpha: colors.version3.success.mainAlpha,
    warning: colors.version3.warning.main,
    warningAlpha: colors.version3.warning.mainAlpha,
    // Other colors. AKA Extended colors
    red: colors.version3.red.main,
    blue: colors.version3.blue.main,
    green: colors.version3.green.main,
    yellow: colors.version3.yellow.main,
    tomato: colors.version3.tomato.main,
    orange: colors.version3.orange.main,
  },
};

const dark = {
  color: {
    // Accent color. AKA Onomondo Copper
    accent: colors.version3.accent.dark,
    accentAlpha: colors.version3.accent.darkAlpha,
    // Neutral color. AKA grayscale
    neutral: colors.version3.neutral.dark,
    neutralAlpha: colors.version3.neutral.darkAlpha,
    // Semantic colors. AKA error, success, warning, info
    error: colors.version3.error.dark,
    errorAlpha: colors.version3.error.darkAlpha,
    info: colors.version3.info.dark,
    infoAlpha: colors.version3.info.darkAlpha,
    success: colors.version3.success.dark,
    successAlpha: colors.version3.success.darkAlpha,
    warning: colors.version3.warning.dark,
    warningAlpha: colors.version3.warning.darkAlpha,
    // Other colors. AKA Extended colors
    red: colors.version3.red.dark,
    blue: colors.version3.blue.dark,
    green: colors.version3.green.dark,
    yellow: colors.version3.yellow.dark,
    tomato: colors.version3.tomato.dark,
    orange: colors.version3.orange.dark,
  },
};

export default { light, dark };
