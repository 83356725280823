import { useContext } from 'react';
import { ThemePickerContext } from '~/context/theme-picker';

function useThemePicker() {
  const context = useContext(ThemePickerContext);
  if (context === undefined) {
    throw new Error('useThemePicker must be used within a ThemePickerProvider');
  }

  return context;
}

export default useThemePicker;
