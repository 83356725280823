import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StatusIndicator from '~/components/StatusIndicator';
import { Text, Link, Tooltip, Flex, Box, DropdownMenu, Button } from '@radix-ui/themes';
import Icon from '~/components/Icon';

const StyledStatusIndicator = styled(StatusIndicator)`
  margin-right: ${props => props.theme.spacing[2]};
  margin-left: ${props => props.theme.spacing[2]};
`;

const STATUS_TO_COPY_MAPPING = {
  operational: 'Operational',
  degraded_performance: 'Degraded performance',
  partial_outage: 'Partial outage',
  major_outage: 'Major outage',
  under_maintenance: 'Under maintenance',
};

function TooltipContent({ type }) {
  return <Text size='2'>{STATUS_TO_COPY_MAPPING[type]}</Text>;
}

TooltipContent.propTypes = {
  type: PropTypes.string,
};

function HealthStatus({ overall, network, webhooks, api, app }) {
  const individualStatuses = [
    { name: 'Network', type: network },
    { name: 'Webhooks', type: webhooks },
    { name: 'API', type: api },
    { name: 'App', type: app },
  ];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          size='1'
          shape='square'
          variant='ghost'
          color='gray'
        >
          <StyledStatusIndicator
            type={overall}
            animated
          />
          <Text weight='bold'>
            {overall === 'operational' ? 'All systems operational' : STATUS_TO_COPY_MAPPING[overall]}
          </Text>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        color='gray'
        size='2'
        variant='soft'
      >
        {individualStatuses.map(item => {
          return (
            <Tooltip
              content={<TooltipContent type={item.type} />}
              key={item.name}
              side='right'
            >
              <DropdownMenu.Item>
                <Flex
                  justify='between'
                  width='128px'
                >
                  <Box>{item.name}</Box>
                  <Box>
                    <StatusIndicator
                      type={item.type}
                      animated
                    />
                  </Box>
                </Flex>
              </DropdownMenu.Item>
            </Tooltip>
          );
        })}
        <DropdownMenu.Separator />
        <DropdownMenu.Item asChild>
          <Link
            href='https://status.onomondo.com'
            weight='regular'
            target='_blank'
            color='gray'
            highContrast
            underline='none'
          >
            <Flex
              justify='between'
              width='100%'
            >
              <Box>
                <Text highContrast>Status Page</Text>
              </Box>
              <Box>
                <Icon
                  name='external-link'
                  width={16}
                  height={16}
                />
              </Box>
            </Flex>
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

HealthStatus.propTypes = {
  children: PropTypes.node,
  overall: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  network: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  webhooks: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  api: PropTypes.oneOf(['operational', 'degraded_performance', 'partial_outage', 'major_outage', 'under_maintenance'])
    .isRequired,
  app: PropTypes.oneOf(['operational', 'degraded_performance', 'partial_outage', 'major_outage', 'under_maintenance'])
    .isRequired,
};

export default HealthStatus;
