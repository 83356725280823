// --------------------
// Colors v2
// 2024 -> 2024
// --------------------
// https://www.figma.com/file/Mmqat1JtfdQjtv3Lu2Dx76
// --------------------

const version2 = {
  primary: {
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EDEDED',
      300: '#D6D6D6',
      400: '#ABABAB',
      500: '#7A7A7A',
      600: '#5E5E5E',
      700: '#525252',
      800: '#474747',
      900: '#3D3D3D',
      950: '#191919',
    },
    copper: {
      50: '#F6F3F0',
      100: '#EAE0D7',
      200: '#D6C2B2',
      300: '#BE9C86',
      400: '#AB7D63',
      500: '#9C6C56',
      600: '#865748',
      700: '#6C433C',
      800: '#5C3B37',
      900: '#503433',
      950: '#2D1B1B',
    },
    green: {
      50: '#EFFAF2',
      100: '#D9F2DE',
      200: '#B6E4C3',
      300: '#86CF9F',
      400: '#53B477',
      500: '#329A5C',
      600: '#227947',
      700: '#1B613A',
      800: '#184D30',
      900: '#144028',
      950: '#0A2417',
    },
    orange: {
      50: '#FFFAEB',
      100: '#FFEFC6',
      200: '#FFDF88',
      300: '#FEC74B',
      400: '#FEB121',
      500: '#F88E08',
      600: '#DC6803',
      700: '#B64707',
      800: '#94360C',
      900: '#792D0E',
      950: '#461502',
    },
    red: {
      50: '#FEF2F2',
      100: '#FDE3E3',
      200: '#FDCBCB',
      300: '#FAA7A7',
      400: '#F56C6C',
      500: '#EC4747',
      600: '#D92929',
      700: '#B61F1F',
      800: '#971D1D',
      900: '#7D1F1F',
      950: '#440B0B',
    },
  },
};

// --------------------
// Colors V3
// 2024 -> 2024
// --------------------
// https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=5429-92415
// --------------------

const V3_RED = {
  main: {
    1: '#FFFCFC',
    2: '#FFF7F7',
    3: '#FFEFEF',
    4: '#FFE5E5',
    5: '#FDD8D8',
    6: '#F9C6C6',
    7: '#F3AEAF',
    8: '#EB9091',
    9: '#E5484D',
    10: '#D93D42',
    11: '#C62A2F',
    12: '#641723',
  },
  dark: {
    1: '#1F1315',
    2: '#291618',
    3: '#3B191D',
    4: '#481A20',
    5: '#551C22',
    6: '#691D25',
    7: '#8C1D28',
    8: '#D21E24',
    9: '#E5484D',
    10: '#F26669',
    11: '#FF8589',
    12: '#FFD1D9',
  },
};
const V3_RED_ALPHA = {
  mainAlpha: {
    1: 'rgba(255, 5, 5, 0.01)',
    2: 'rgba(255, 5, 5, 0.03)',
    3: 'rgba(255, 1, 1, 0.06)',
    4: 'rgba(255, 0, 0, 0.1)',
    5: 'rgba(242, 0, 0, 0.15)',
    6: 'rgba(228, 1, 1, 0.22)',
    7: 'rgba(217, 0, 3, 0.32)',
    8: 'rgba(209, 0, 3, 0.44)',
    9: 'rgba(219, 0, 7, 0.72)',
    10: 'rgba(205, 0, 8, 0.76)',
    11: 'rgba(187, 0, 7, 0.84)',
    12: 'rgba(85, 0, 13, 0.91)',
  },
  darkAlpha: {
    1: 'rgba(250, 0, 0, 0.03)',
    2: 'rgba(254, 0, 25, 0.07)',
    3: 'rgba(254, 31, 57, 0.15)',
    4: 'rgba(255, 33, 62, 0.21)',
    5: 'rgba(254, 39, 61, 0.26)',
    6: 'rgba(255, 38, 60, 0.35)',
    7: 'rgba(255, 34, 56, 0.5)',
    8: 'rgba(255, 31, 40, 0.81)',
    9: 'rgba(255, 78, 84, 0.89)',
    10: 'rgba(255, 107, 109, 0.94)',
    11: 'rgba(255, 133, 138, 1)',
    12: 'rgba(255, 209, 217, 1)',
  },
};
const V3_BLUE = {
  main: {
    1: '#FBFDFF',
    2: '#F5FAFF',
    3: '#EDF6FF',
    4: '#E1F0FF',
    5: '#CEE7FE',
    6: '#B7D9F8',
    7: '#96C7F2',
    8: '#5EB0EF',
    9: '#0091FF',
    10: '#0880EA',
    11: '#0B68CB',
    12: '#113264',
  },
  dark: {
    1: '#0F1720',
    2: '#0F1B2D',
    3: '#11253F',
    4: '#102A4C',
    5: '#12325A',
    6: '#123D6F',
    7: '#0F5096',
    8: '#1276E2',
    9: '#0091FF',
    10: '#3CABFF',
    11: '#6BC1FF',
    12: '#C2E5FF',
  },
};
const V3_BLUE_ALPHA = {
  mainAlpha: {
    1: 'rgba(5, 130, 255, 0.02)',
    2: 'rgba(2, 128, 255, 0.07)',
    3: 'rgba(2, 128, 255, 0.07)',
    4: 'rgba(1, 125, 250, 0.19)',
    5: 'rgba(1, 131, 250, 0.19)',
    6: 'rgba(1, 121, 230, 0.28)',
    7: 'rgba(0, 119, 223, 0.41)',
    8: 'rgba(0, 130, 230, 0.63)',
    9: 'rgba(0, 145, 255, 1)',
    10: 'rgba(0, 125, 234, 0.97)',
    11: 'rgba(0, 97, 201, 0.96)',
    12: 'rgba(0, 36, 89, 0.93)',
  },
  darkAlpha: {
    1: 'rgba(0, 0, 252, 0.04)',
    2: 'rgba(0, 102, 255, 0.17)',
    3: 'rgba(0, 102, 255, 0.17)',
    4: 'rgba(0, 110, 254, 0.23)',
    5: 'rgba(3, 117, 255, 0.29)',
    6: 'rgba(8, 125, 255, 0.38)',
    7: 'rgba(7, 128, 255, 0.55)',
    8: 'rgba(17, 131, 255, 0.88)',
    9: 'rgba(0, 145, 255, 1)',
    10: 'rgba(60, 171, 255, 1)',
    11: 'rgba(107, 193, 255, 1)',
    12: 'rgba(194, 229, 255, 1)',
  },
};
const V3_GREEN = {
  main: {
    1: '#FBFEFC',
    2: '#F2FCF5',
    3: '#E9F9EE',
    4: '#DDF3E4',
    5: '#CCEBD7',
    6: '#B4DFC4',
    7: '#92CEAC',
    8: '#5BB98C',
    9: '#30A46C',
    10: '#299764',
    11: '#18794E',
    12: '#193B2D',
  },
  dark: {
    1: '#0D1912',
    2: '#0F1D17',
    3: '#12281F',
    4: '#143125',
    5: '#173A2A',
    6: '#194633',
    7: '#1F5E41',
    8: '#2C8C5E',
    9: '#30A46C',
    10: '#35B979',
    11: '#3DD68C',
    12: '#B1F1CB',
  },
};
const V3_GREEN_ALPHA = {
  mainAlpha: {
    1: 'rgba(5, 192, 67, 0.02)',
    2: 'rgba(2, 186, 60, 0.09)',
    3: 'rgba(2, 186, 60, 0.09)',
    4: 'rgba(1, 166, 54, 0.13)',
    5: 'rgba(0, 155, 55, 0.2)',
    6: 'rgba(1, 147, 55, 0.29)',
    7: 'rgba(0, 140, 61, 0.43)',
    8: 'rgba(0, 147, 77, 0.64)',
    9: 'rgba(0, 143, 74, 0.81)',
    10: 'rgba(0, 131, 71, 0.84)',
    11: 'rgba(0, 107, 59, 0.91)',
    12: 'rgba(0, 38, 22, 0.9)',
  },
  darkAlpha: {
    1: 'rgba(0, 224, 0, 0)',
    2: 'rgba(0, 251, 0, 0.02)',
    3: 'rgba(0, 252, 122, 0.07)',
    4: 'rgba(0, 253, 143, 0.11)',
    5: 'rgba(17, 254, 147, 0.15)',
    6: 'rgba(29, 254, 160, 0.2)',
    7: 'rgba(47, 254, 158, 0.3)',
    8: 'rgba(64, 255, 163, 0.5)',
    9: 'rgba(63, 255, 162, 0.61)',
    10: 'rgba(66, 255, 164, 0.7)',
    11: 'rgba(69, 255, 166, 0.82)',
    12: 'rgba(187, 255, 214, 0.94)',
  },
};
const V3_YELLOW = {
  main: {
    1: '#FDFDF9',
    2: '#FFFBE0',
    3: '#FFF8C6',
    4: '#FCF3AF',
    5: '#F7EA9B',
    6: '#ECDD85',
    7: '#DAC56E',
    8: '#C9AA45',
    9: '#FBE32D',
    10: '#F9DA10',
    11: '#775F28',
    12: '#473B1F',
  },
  dark: {
    1: '#1C1500',
    2: '#221A04',
    3: '#2C230A',
    4: '#342A0E',
    5: '#3D3211',
    6: '#493D14',
    7: '#615119',
    8: '#8F7D24',
    9: '#FBE32D',
    10: '#FCEA5C',
    11: '#FFEE33',
    12: '#FFF5AD',
  },
};
const V3_YELLOW_ALPHA = {
  mainAlpha: {
    1: 'rgba(171, 171, 5, 0.02)',
    2: 'rgba(255, 221, 1, 0.12)',
    3: 'rgba(255, 227, 1, 0.22)',
    4: 'rgba(246, 217, 0, 0.31)',
    5: 'rgba(235, 203, 1, 0.39)',
    6: 'rgba(215, 183, 0, 0.48)',
    7: 'rgba(190, 152, 0, 0.57)',
    8: 'rgba(181, 139, 0, 0.73)',
    9: 'rgba(250, 220, 0, 0.82)',
    10: 'rgba(249, 216, 0, 0.94)',
    11: 'rgba(94, 66, 0, 0.84)',
    12: 'rgba(46, 32, 0, 0.88)',
  },
  darkAlpha: {
    1: 'rgba(246, 0, 0, 0.02)',
    2: 'rgba(251, 71, 0, 0.04)',
    3: 'rgba(254, 152, 0, 0.09)',
    4: 'rgba(254, 174, 0, 0.12)',
    5: 'rgba(254, 186, 0, 0.16)',
    6: 'rgba(254, 196, 5, 0.21)',
    7: 'rgba(254, 205, 27, 0.32)',
    8: 'rgba(255, 222, 47, 0.52)',
    9: 'rgba(255, 230, 45, 0.98)',
    10: 'rgba(255, 236, 92, 0.99)',
    11: 'rgba(255, 238, 51, 1)',
    12: 'rgba(255, 246, 173, 1)',
  },
};
const V3_GRAY = {
  main: {
    1: '#FCFCFD',
    2: '#F9F9FB',
    3: '#F2F2F5',
    4: '#EBEBEF',
    5: '#E4E4E9',
    6: '#DDDDE3',
    7: '#D3D4DB',
    8: '#B9BBC6',
    9: '#8B8D98',
    10: '#7E808A',
    11: '#60646C',
    12: '#1C2024',
  },
  dark: {
    1: '#18181A',
    2: '#1B1B1F',
    3: '#27282D',
    4: '#2E3035',
    5: '#35373C',
    6: '#3C3F44',
    7: '#464B50',
    8: '#5A6165',
    9: '#696E77',
    10: '#787F85',
    11: '#ADB1B8',
    12: '#EDEEF0',
  },
};
const V3_GRAY_ALPHA = {
  mainAlpha: {
    1: 'rgba(5, 5, 88, 0.01)',
    2: 'rgba(5, 5, 88, 0.02)',
    3: 'rgba(0, 0, 59, 0.05)',
    4: 'rgba(2, 2, 52, 0.08)',
    5: 'rgba(0, 0, 48, 0.11)',
    6: 'rgba(1, 1, 46, 0.13)',
    7: 'rgba(1, 6, 47, 0.17)',
    8: 'rgba(0, 8, 47, 0.28)',
    9: 'rgba(0, 4, 29, 0.46)',
    10: 'rgba(0, 4, 24, 0.51)',
    11: 'rgba(0, 7, 19, 0.62)',
    12: 'rgba(0, 5, 9, 0.89)',
  },
  darkAlpha: {
    1: 'rgba(24, 24, 246, 0.01)',
    2: 'rgba(121, 121, 250, 0.03)',
    3: 'rgba(189, 200, 255, 0.09)',
    4: 'rgba(199, 214, 254, 0.13)',
    5: 'rgba(210, 223, 255, 0.16)',
    6: 'rgba(212, 228, 254, 0.19)',
    7: 'rgba(213, 234, 254, 0.24)',
    8: 'rgba(223, 242, 253, 0.33)',
    9: 'rgba(221, 233, 255, 0.41)',
    10: 'rgba(228, 238, 255, 0.49)',
    11: 'rgba(239, 245, 255, 0.69)',
    12: 'rgba(252, 253, 255, 0.94)',
  },
};
const V3_ORANGE = {
  main: {
    1: '#FEFCFB',
    2: '#FFF7ED',
    3: '#FFEFD6',
    4: '#FFDFB5',
    5: '#FFD19A',
    6: '#FFC182',
    7: '#F5AE73',
    8: '#EC9455',
    9: '#F76B15',
    10: '#EF5F00',
    11: '#CC4E00',
    12: '#582D1D',
  },
  dark: {
    1: '#17120E',
    2: '#1E160F',
    3: '#331E0B',
    4: '#462100',
    5: '#562800',
    6: '#66350C',
    7: '#7E451D',
    8: '#A35829',
    9: '#F76B15',
    10: '#FF801F',
    11: '#FFA057',
    12: '#FFE0C2',
  },
};
const V3_ACCENT = {
  main: {
    1: '#FDFCFC',
    2: '#FEF9F6',
    3: '#F9F0EC',
    4: '#F4E8E1',
    5: '#EDDDD4',
    6: '#E5CFC3',
    7: '#D6B8A8',
    8: '#C79F8A',
    9: '#AB7D63',
    10: '#A0725A',
    11: '#885B49',
    12: '#4A3026',
  },
  mainAlpha: {
    1: 'rgba(88, 5, 5, 0.01)',
    2: 'rgba(199, 73, 5, 0.04)',
    3: 'rgba(151, 56, 1, 0.07)',
    4: 'rgba(132, 46, 0, 0.11)',
    5: 'rgba(132, 51, 0, 0.16)',
    6: 'rgba(122, 43, 0, 0.22)',
    7: 'rgba(132, 44, 0, 0.33)',
    8: 'rgba(132, 47, 1, 0.46)',
    9: 'rgba(117, 43, 0, 0.61)',
    10: 'rgba(107, 38, 1, 0.65)',
    11: 'rgba(86, 25, 0, 0.71)',
    12: 'rgba(41, 11, 0, 0.85)',
  },
  dark: {
    1: '#1C1512',
    2: '#1E1815',
    3: '#2B221D',
    4: '#352922',
    5: '#3E2F28',
    6: '#4C392F',
    7: '#664C3D',
    8: '#9A725B',
    9: '#AB7D63',
    10: '#BD947A',
    11: '#DBB49F',
    12: '#F0E1D6',
  },
  darkAlpha: {
    1: 'rgba(224, 0, 0, 0)',
    2: 'rgba(246, 80, 24, 0.02)',
    3: 'rgba(255, 178, 147, 0.06)',
    4: 'rgba(255, 188, 159, 0.1)',
    5: 'rgba(254, 190, 168, 0.14)',
    6: 'rgba(254, 194, 169, 0.2)',
    7: 'rgba(255, 197, 175, 0.3)',
    8: 'rgba(255, 201, 178, 0.51)',
    9: 'rgba(255, 199, 175, 0.59)',
    10: 'rgba(255, 206, 184, 0.67)',
    11: 'rgba(255, 215, 197, 0.81)',
    12: 'rgba(255, 241, 233, 0.92)',
  },
};
const V3_TOMATO = {
  main: {
    1: '#FFFCFC',
    2: '#FFF8F7',
    3: '#FEEBE7',
    4: '#FFDCD3',
    5: '#FFCDC2',
    6: '#FDBDAF',
    7: '#F5A898',
    8: '#EC8E7B',
    9: '#E54D2E',
    10: '#DD4425',
    11: '#D13415',
    12: '#5C271F',
  },
  dark: {
    1: '#181111',
    2: '#1F1513',
    3: '#391714',
    4: '#4E1511',
    5: '#5E1C16',
    6: '#6E2920',
    7: '#853A2D',
    8: '#AC4D39',
    9: '#E54D2E',
    10: '#EC6142',
    11: '#FF977D',
    12: '#FBD3CB',
  },
};

const version3 = {
  // There is other colors, but we are only using these ones for now.
  // See all colors here: https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=5429-92415&m=dev
  accent: V3_ACCENT,
  tomato: V3_TOMATO,
  red: V3_RED,
  blue: V3_BLUE,
  green: V3_GREEN,
  yellow: V3_YELLOW,
  gray: V3_GRAY,
  neutral: { ...V3_GRAY, ...V3_GRAY_ALPHA },
  success: { ...V3_GREEN, ...V3_GREEN_ALPHA },
  error: { ...V3_RED, ...V3_RED_ALPHA },
  warning: { ...V3_YELLOW, ...V3_YELLOW_ALPHA },
  info: { ...V3_BLUE, ...V3_BLUE_ALPHA },
  orange: V3_ORANGE,
};

export default {
  version2,
  version3,
};
